<template>
  <div
    v-if="show"
    class="modal"
    @click="handleClick"
  >
    <div class="transition">
      <div
        class="text"
        :style="`--maxHeight: ${maxHeight}`"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: '70vh'
    }
  },
  methods: {
    handleClick (e) {
      if (e.target.className === 'transition') {
        this.$emit('close-modal')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@keyframes show-modal {
  0% {
    opacity: 0;
    transform: scale(0, 0) translate(50%, 50%);
    transform-origin: 90% 80%;
  }
  100% {
    opacity: 1;
    transform: scale(100%, 100%) translate(0, 0);
  }
}

.modal
  position fixed
  top 0
  left 0
  height 100vh
  width 100vw
  background rgba(0, 0, 0, 0.5)
  z-index 3000
  .transition
    display flex
    align-items center
    justify-content center
    height 100%
    width 100%
    animation show-modal 0.3s ease-in
  .text
    background white
    padding 5px
    border-radius 5px
    width 90vw
    height fit-content
    max-height var(--maxHeight)
    overflow-y scroll
</style>
